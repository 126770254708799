//login
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
//signup
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_FAILED = "SIGNUP_USER_FAILED";
//recover
export const RECOVER_USER = "RECOVER_USER";
export const RECOVER_USER_FAILED = "RECOVER_USER_FAILED";

