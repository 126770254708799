import Lang from "./Lang";

class El extends Lang{
    translations :any =  {
        common : {
            "0001" : "Συμπληρώστε όλα τα πεδία",
            "0002" : "Όροι χρήσης",
            "0003" : "Οι κωδικοί δεν ταιριάζουν",
            "0004" : "Ξεχάσατε τον κωδικό;",
            "0005" : "Γλώσσα",
            "0006" : "Παρακαλώ δώστε ένα έγκυρο email",
            "0007" : "Παρακαλώ επιβεβαιώστε το email σας"
        },
        login : {
            "0001" : "Email",
            "0002" : "Κωδικός",
            "0003" : "Σύνδεση",
            "0004" : "Εγγραφή"     
        },
        signup : {
            "0001" : "Email",
            "0002" : "Κωδικός",
            "0003" : "Επαλήθευση κωδικού",
            "0004" : "Σύνδεση",
            "0005" : "Εγγραφή"
        },
        forgot:{
            "0001" : "Email",
            "0002" : "Σύνδεση",
            "0003" : "Αποστολή",
            "0004" : "Ελέξτε το email σας"
        }
    }
}

export default El;
